<template>
    <div class="container-profile">
        <div class="main-container-background mb-4" :style="`background-color:${urlImage}`">
            <div class="main-container-logo">
                <div class="J-profile-logo-content" style="width: 120px; height: 120px;">
                    <div class="J-profile-logo">
                        <img id="avatar" decoding="async" loading="lazy" :src="dataUser.photo
                            ? dataUser.photo.link
                            : dataUser.image
                                ? dataUser.image
                                : imageUrl
                            " v-if="!response.loading" />
                        <span v-else class="J-profile-nonimage">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </span>
                    </div>
                    <UploadPartials title="Cargar archivo" accept="image/*" @data="savedData" button="camera" />
                </div>
            </div>
            <div class="container-title-company" v-for="(item, i) in information" :key="i + 1">
                <h1 class="text-style" style="font-family: Poppins;">{{ item.name }} {{ item.lastname }} {{
                    item.secondlastname }}</h1>
                <div class="container-information-secondary">
                    <div v-if="item.status == 'preuniversitario'">
                        <span class="text-style-secondary">PRE-UNIVERSITARIO</span>
                    </div>
                    <div v-else-if="item.status === 'universitario'">
                        <span class="text-style-secondary">UNIVERSITARIO</span>
                    </div>
                    <div v-else-if="item.status === 'exuniversitario'">
                        <span class="text-style-secondary">EX-UNIVERSITARIO</span>
                    </div>
                </div>
            </div>
            <div v-for="(ver, i) in verifiedData" :key="i + 1" style="width: 12%;">  
                 <img src="/images/imagenV3.png" alt="Tienda" style="width: 100%; height: 100%;" v-if="ver.verificationStatus"/>
            </div>
        </div>
        <div class="tarjetas-contadores" style="display: flex; flex-direction: row; justify-content: space-evenly">
            <!-- Tarjeta nueva con enlace dinámico usando el slug (solo se muestra si existe universityDetails en student) -->
            <div v-for="(item, i) in information" :key="i + 1" v-show="item.universityDetails" class="newCardCount card shadow-lg" style="width: 25%;">
                <div class="card">
                    <div @click="linkUniversidad(item.universityDetails.slug || 'upcoming')" style="cursor: pointer;">
                        <img :src="'/images/Vac.png'" alt="Portal Universidad" style="width: 100%; height: auto" />
                    </div>
                </div>
            </div>

            <!-- Tarjeta para Bolsa Universitaria -->
            <div class="newCardCount card shadow-lg" style="width: 25%;">
                <div class="card">
                    <a :href="'/uservacantes'" target="_blank">
                        <img :src="'/images/Vacantes.png'" alt="Bolsa Universitaria" style="width: 100%; height: auto" />
                    </a>
                </div>
            </div>

            <!-- Tarjeta para Tienda Universitaria -->
            <div class="newCardCount card shadow-lg" style="width: 25%;">
                <div class="card">
                    <a :href="'https://shop.mundouniversitario.com/'" target="_blank">
                        <img :src="'/images/store.png'" alt="Tienda Universitaria" style="width: 100%; height: auto" />
                    </a>
                </div>
            </div>
        </div>

        <!-- Tarjeta de Estatus False -->
        <div class="tarjetas-status">
            <div class="card border-secondary mb-3" style="max-width: 40%;" v-if="dataUser.status === false">
                <div class="card-header bg-secondary text-white mb-2">
                    <h5 class="card-title">¡PERFIL EN REVISIÓN!</h5>
                </div>
                <div class="contenido-span">
                    <box-icon name='block' size="lg" color="red"></box-icon>
                </div>
                <div class="card-body center" style="text-align: center; font-family:Poppins">
                    <p class="card-text">Tu perfil está en proceso de aprobación. <br> Aún no podrás disponer de todo el
                        contenido
                        y funcionalidades de "Mundo Universitario" como ver vacantes. <br> Te notificaremos vía email tu
                        aceptación. </p>
                </div>
            </div>
        </div>
        <div class="toast align-items-center" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    Hello, world! This is a toast message.
                </div>
                <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>
    </div>
</template>

<script>
import { UserService } from "@/services";
import { AdminService } from "@/services";
import UploadPartials from "@/components/partials/upload.partials.vue";
import moment from "moment";
moment.locale("es");


export default {
    data: () => {
        return {
            dataUser: {},
            information: {},
            verifiedData: {},
            imageName: "",
            imageUrl: "/images/profile.jpg",
            imageFile: "",
            response: {
                loading: false,
            },
            urlImage: "#f5782d",
            promoCodes: [],
            moment: moment,
        };
    },
    beforeMount() {
        this.extractionInformation();
        this.extractionVerification();
        this.loadPromoCodes();
    },
    mounted() {
        this.dataUser = JSON.parse(localStorage.getItem("user") || null);
        this.dataUser._id = this.userID;
        console.log(this.dataUser)
        if (this.dataUser.photo == undefined) {
            this.$swal({
                position: "top-center",
                icon: "warning",
                timer: 5000,
                html: "<center>¡ACTUALIZA TU FOTO DE PERFIL CON UNA FOTO PROFESIONAL!</center>",
                confirmButtonText: 'Continuar <i class="fa fa-thumbs-up"></i> ',
            });
        }
    },
    methods: {
        async extractionInformation() {
            await UserService.getStudent().then((response) => {
                const student = response.data;
                this.information = student;
                console.log("data", student)
            });
        },
        removeItem(sKey, sPath, sDomain) {
            document.cookie =
                encodeURIComponent(sKey) +
                "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" +
                (sDomain ? "; domain=" + sDomain : "") +
                (sPath ? "; path=" + sPath : "");
        },
        async savedData(file) {
            try {
                this.response.loading = true;
                var resp = await UserService.savedImage({ file: file });
                var { data } = resp.data;
                var user = JSON.parse(localStorage.getItem("user") || null);
                user.photo = data;
                this.dataUser = user;
                this.response.loading = false;
                localStorage.setItem("user", JSON.stringify(user));
            } catch ($e) {
                this.response.loading = false;
            }
        },

        //TODO: Calcular Edad De Postulantes
        getEdad(dateString) {
            let hoy = new Date()
            let fechaNacimiento = new Date(dateString)
            let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
            let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
            if (
                diferenciaMeses < 0 ||
                (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
            ) {
                edad--
            }
            return edad
        },
        // async loadPromoCodes() {
        //     try {
        //         const response = await AdminService.getAllPromos();
        //         const currentDate = new Date();
        //         this.promoCodes = response.data.filter(promo => {
        //         return new Date(promo.expirationDate) > currentDate;
        //         });
        //     } catch (error) {
        //         console.error('Error al cargar los códigos promocionales:', error);
        //     }
        // },
        async loadPromoCodes() {
            await UserService.getAllPromos().then((response) => {
                const currentDate = new Date();
                this.promoCodes = response.data.filter(promo => {
                    return new Date(promo.expirationDate) > currentDate;
                });
            }).catch((error) => {
                console.error('Error al cargar los códigos promocionales:', error);
            });
        },
        async extractionVerification() {
            await UserService.getVerifiedData().then((response) => {
                const studentVerified = response.data;
                this.verifiedData = studentVerified;
            });
        },
        linkUniversidad(slug) {
            window.open(`/${slug}`, '_blank');
        },
    },
    components: {
        UploadPartials,
    },
};
</script>
<style land="scss">
@import "../../styles/profile.scss";
</style>
